<template>
  <div>
    <div class="screen clearfix" style="margin-top: 10px">
      <div class="item">
        <span>访问用户</span>
        <div class="box">
          <el-input v-model="inquire.name" placeholder="输入访问账号或员工姓名" style="width: 210px"></el-input>
        </div>
      </div>
      <div class="item">
        <span>所属组织</span>
        <div class="box">
          <el-cascader style="width: 210px;" v-model="inquire.tissue" :options="proClass" collapse-tags :props="{value: 'id', label: 'name', multiple: true,checkStrictly: true}" clearable></el-cascader>
        </div>
      </div>
      <div class="item">
        <span>访问时间</span>
        <div class="box">
          <el-date-picker
              v-model="inquire.registrationTime"
              class="daterange"
              type="datetimerange"
              range-separator="至"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 234px"
              @change="handleChangeDate">
          </el-date-picker>
        </div>
      </div>
      <div class="item">
        <span>访问终端</span>
        <div class="box">
          <el-select v-model="inquire.terminal" filterable :popper-append-to-body="false" placeholder="全部"
                     style="width: 180px">
            <el-option
                v-for="item in loginTerminal"
                :key="item.id"
                :label="item.value"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <span class="button inquire" @click="inquireBtn">查询</span>
      <span class="button" @click="resetBtn">重置</span>
    </div>
    <div class="main_table">
      <el-table
          ref="multipleTable"
          :data="tableData"
          :border="true"
          v-loading="loading"
          @selection-change="handleSelectionChange"
          style="width: 100%">
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column
            label="序号"
            min-width="18%"
            style="text-align: center"
        >
          <template slot-scope="scope">
            <span>{{ (page - 1) * limit + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="account"
            label="访问账号">
        </el-table-column>
        <el-table-column
            prop="full_name"
            label="员工姓名">
        </el-table-column>
        <el-table-column
            prop="hasOneAdmin.organize_node_text"
            label="所属组织">
        </el-table-column>
        <el-table-column
            prop="menu_name"
            label="访问模块"
            min-width="100%">
        </el-table-column>
        <el-table-column
            prop="create_time"
            label="访问时间">
        </el-table-column>
        <el-table-column
            prop="ip"
            label="访问IP">
        </el-table-column>
        <el-table-column
            label="访问终端">
          <template slot-scope="scope">
            <span>{{getTerminal(scope.row.terminal)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="operate">
        <div class="handle">
          <div class="all-select">
            <el-checkbox :indeterminate="isIndeterminate" v-model="allSelect" @change="toggleSelection()"></el-checkbox>
          </div>
          <el-dropdown placement="top-start">
            <div class="button el-dropdown-link">
              导出
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="alsoData">导出已选数据</el-dropdown-item>
              <el-dropdown-item @click.native="allData">导出全部数据</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="limit"
            layout="prev, pager, next, sizes"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "access-log",
  data(){
    return {
      proClass:[],//所属组织数据
      loading:true,
      total:null,//分页器总数
      page:1,
      limit:10,
      type:2,//日志类型1=登录 2=访问 3=操作
      keywords:'',//账号或姓名
      terminal:'',//端口1=pc 2=ipad
      menu_name:'',//模块名
      start_time:'',//开始时间
      end_time:'',//结束时间
      organize_id:[],//所属组织
      registrationTime:'',
      isIndeterminate: false, // 表格是否有选中但未全选
      allSelect: false, // 表格全选
      multipleSelection: [], // 表格选中列表
      inquire:{
        name:'',
        registrationTime:[],
        terminal:'',
        tissue:[]
      },
      loginTerminal:[
        {
          id:1,
          value:'PC端'
        },
        {
          id:2,
          value:'PAD端'
        }
      ],
      tableData: []
    }
  },
  mounted() {
    this.getData();
    this.getProClass();
  },
  methods:{
    getProClass(){
      this.$api.getOrganizeTreeList().then(res=>{
        this.proClass = res.data
      })
    },
    alsoData(){
      const id = this.multipleSelection.map(item=>item.id)
      const data = {
        id:id,
        type:2
      }
      this.$api.system_log_list(data).then(res=>{
        this.fileDownload(res.data.excel_url)
      })
    },
    allData(){
      this.$api.system_log_list({type:2}).then(res=>{
        this.fileDownload(res.data.excel_url)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.isIndeterminate = val.length > 0 && val.length < this.tableData.length;
      this.allSelect = val.length === this.tableData.length ? true : false;
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    getTerminal(row){
      if (row == 1){
        return 'PC端'
      }else if (row == 2){
        return 'PAD端'
      }
    },
    resetBtn(){
      this.keywords = this.inquire.name = ''
      this.terminal = this.inquire.terminal = ''
      this.inquire.registrationTime = []
      this.start_time = ''
      this.organize_id = this.inquire.tissue = []
      this.end_time = ''
      this.page = 1
      this.getData();
    },
    inquireBtn(){ //查询方法
      console.log(this.inquire,1)
      this.keywords = this.inquire.name;
      if (this.inquire.registrationTime != null){
        this.start_time = this.inquire.registrationTime[0]
        this.end_time = this.inquire.registrationTime[1]
      }
      this.organize_id = this.inquire.tissue
      this.terminal = this.inquire.terminal
      this.page = 1
      this.getData();
    },
    getData(){
      let data = {
        page:this.page,
        limit:this.limit,
        type:this.type,
        keywords:this.keywords,
        terminal:this.terminal,
        organize_id:this.organize_id.map(subArray => [subArray[subArray.length - 1]]),
        menu_name:this.menu_name,
        start_time:this.start_time,
        end_time:this.end_time
      }
      this.loading = true;
      this.$api.log_list(data).then(res=>{
        this.total = res.data.count
        this.tableData = res.data.data
        this.loading = false
        console.log(res,1471)
      })
    },

    handleSizeChange(val) {
      this.limit = val;
      this.getData();
    }, // 分页pageSize改变
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    handleChangeDate(){}
  }
}
</script>
<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
::v-deep .el-table--border .el-table__cell:first-child .cell{
  text-align: center;
}