<template>
  <div class="container">
    <titleDom :title="'系统日志'" style="margin-bottom: 15px;"/>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="登录日志" name="first"><entry-log v-if="activeName === 'first'"></entry-log></el-tab-pane>
      <el-tab-pane label="访问日志" name="second"><access-log v-if="activeName === 'second'"></access-log></el-tab-pane>
      <el-tab-pane label="操作日志" name="third"><operation-log v-if="activeName === 'third'"></operation-log></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import titleDom from "@/components/common/titleDom.vue";
import entryLog from "./components/entry-log";
import accessLog from "./components/access-log";
import operationLog from "./components/operation-log";
export default {
  name: "index",
  components: {titleDom,entryLog,accessLog,operationLog},
  data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 18px;
  padding: 30px 30px;
  width: calc(100% - 96px);
  height: calc(100% - 96px);
  background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;

  // tags样式修改
  ::v-deep .el-tabs__nav-wrap::after{
    background: #EEEFF2;
    height: 1px;
  }
  ::v-deep .el-tabs__item.is-active{
    color: #3984F5;
    font-weight: bold;
  }
  ::v-deep .el-tabs__item{
    &:hover{
      color: #3984F5;
    }
  }
}
</style>